// /**
//  * File navigation.js.
//  *
//  * Handles toggling the navigation menu for small screens and enables TAB key
//  * navigation support for dropdown menus.
//  */
// ( function () {
//     let i;
//     let len;

//     const container = document.getElementById( "site-navigation" );
//     if ( ! container ) {
//         console.log("Navigation container not found. Navigation exiting.");
//         return;
//     }

//     const menu = container.getElementsByTagName( "ul" )[0];

//     menu.setAttribute( "aria-expanded", "false" );
//     if ( -1 === menu.className.indexOf( "nav-menu" ) ) {
//         menu.className += " nav-menu";
//     }

//     function toggleFocus() {
//         let self = this;

//         // Move up through the ancestors of the current link until we hit .nav-menu.
//         while ( -1 === self.className.indexOf( "nav-menu" ) ) {

//             // On li elements toggle the class .focus.
//             if ( "li" === self.tagName.toLowerCase() ) {
//                 if ( -1 !== self.className.indexOf( "focus" ) ) {
//                     self.className = self.className.replace( " focus", "" );
//                 } else {
//                     self.className += " focus";
//                 }
//             }

//             self = self.parentElement;
//         }
//     }

//     // Get all the link elements within the menu.
//     const links = menu.getElementsByTagName( "a" );

//     // Each time a menu link is focused or blurred, toggle focus.
//     for ( i = 0, len = links.length; i < len; i++ ) {
//         links[i].addEventListener( "focus", toggleFocus, true );
//         links[i].addEventListener( "blur", toggleFocus, true );
//     }

//     ( function ( container ) {
//         let touchStartFn;
//         let i;
//         const parentLink = container.querySelectorAll( ".menu-item-has-children > a, .page_item_has_children > a" );

//         if ( "ontouchstart" in window ) {
//             touchStartFn = function ( e ) {
//                 const menuItem = this.parentNode; let i;

//                 if ( ! menuItem.classList.contains( "focus" ) ) {
//                     e.preventDefault();
//                     for ( i = 0; i < menuItem.parentNode.children.length; ++i ) {
//                         if ( menuItem === menuItem.parentNode.children[i] ) {
//                             continue;
//                         }
//                         menuItem.parentNode.children[i].classList.remove( "focus" );
//                     }
//                     menuItem.classList.add( "focus" );
//                 } else {
//                     menuItem.classList.remove( "focus" );
//                 }
//             };

//             for ( i = 0; i < parentLink.length; ++i ) {
//                 parentLink[i].addEventListener( "touchstart", touchStartFn, false );
//             }
//         }
//     }( container ) );
// } )();
