// JavaScript Document

// Scripts written by JOrdan @ Webinx, Inc.

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
    const toggles = document.querySelectorAll(".mobile-nav .menu-item-has-children");
    jQuery(".mobile-icon a").on("click touchstart", function (event) {
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("open-menu");
        toggles.forEach (toggle => {
            toggle.classList.remove("is-active");
        });
        console.log("deactivated clicked mobile icon");
    });

    jQuery(document).on("click touchstart", function () {
        jQuery("body").removeClass("open-menu");
        toggles.forEach (toggle => {
            toggle.classList.remove("is-active");
        });
        console.log("deactivated - clicked body");
    });

    jQuery(".site-header .header-right").on("click touchstart", function (event) {
        event.stopPropagation();
    });

    jQuery(".touch .navigation ul li > a").on("click touchstart",function (event) {

        if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
        {
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });

    jQuery(".search-section .search-bar").click(function (event) {
        event.stopPropagation();
        jQuery(this).next(".search-form").slideToggle();
    });

    jQuery(document).on("click", function (e) {
        const popup = jQuery(".search-section");
        if (!jQuery(".search-form").is(e.target) && !popup.is(e.target) && popup.has(e.target).length == 0) {
            jQuery(".search-form").slideUp();
        }
    });

    jQuery(".banner-slider").slick({
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        nextArrow: "<div role='button' class='slick-arrow slick-next' title='Next Slide'><i class='far fa-angle-right'></i></div>",
        prevArrow: "<div role='button' class='slick-arrow slick-prev' title='Previous Slide'><i class='far fa-angle-right'></i></div>",
    });
});

jQuery(document).ready(function () {
    function close_accordion_section() {
        jQuery(".accordion .accordion-section-title").removeClass("active");
        jQuery(".accordion .accordion-section-content").slideUp(300).removeClass("open");
    }

    jQuery(".accordion-section-title").click(function (e) {
    // Grab current anchor value
        const currentAttrValue = jQuery(this).attr("href");

        if (jQuery(e.target).is(".active")) {
            close_accordion_section();
        } else {
            close_accordion_section();

            // Add active class to section title
            jQuery(this).addClass("active");
            // Open up the hidden content panel
            jQuery(`.accordion ${currentAttrValue}`).slideDown(300).addClass("open");
        }

        e.preventDefault();
    });
});
